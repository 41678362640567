<template>
  <div v-if="isshowgrid">
    <va-card title="Operator Advertisement Slot List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-input
              v-model="org_name"
              type="text"
              label="Organization Name*"
              :error="!!orgNameErrors.length"
              :error-messages="orgNameErrors"
              :disabled="true"
            />
            <va-input
              v-if="isUpdate"
              v-model="slot_time"
              type="text"
              min="1"
              label="slot time*"
              placeholder="Enter Duration in seconds"
              :error="!!durationErrors.length"
              :error-messages="durationErrors"
              :disabled="true"
            />
            <va-input
              v-model="duration"
              type="number"
              min="1"
              label="duration* (Max value 20)"
              placeholder="Enter Duration in seconds"
              @input="if(Number(duration) > 20) duration = '';"
              :error="!!durationErrors.length"
              :error-messages="durationErrors"
            />
            <va-input
              v-model="repetitive_count"
              type="number"
              min="1"
              label="repetitive count* (Max value 6)"
              @input="if(Number(repetitive_count) > 6) repetitive_count = '';"
              placeholder="Enter Repetitive Count"
              :error="!!repetitiveCountErrors.length"
              :error-messages="repetitiveCountErrors"
            />
            <va-input
              v-model="amount"
              type="number"
              min="1"
              label="Amount* (Specify in Rupees)"
              placeholder="Enter Amount"
              :error="!!amountErrors.length"
              :error-messages="amountErrors"
            />
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateOperatorAdSlot()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'operator_adslot',
  beforeCreate () {
    var userRole = Vue.$cookies.get('roles')
    if (userRole.includes('ADMIN')) {
      this.role = true
      this.operator_role = false
    } else if (userRole.includes('RESELLER')) {
      this.role = false
      this.operator_role = false
    } else if (userRole.includes('OPERATOR')) {
      this.operator_role = true
    }
    this.getOperatorAdvertisementSlotList()
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
    // this.$http.get(config.menu.host + '/advertisement_slot/operator_advertisement').then(response => {
    //   loader.hide()
    //   this.operator_ad_slot_list = response.body
    //   this.isshowForm = false
    //   this.isshowgrid = true
    //   this.getOperatorAdvertisementSlotList()
    // }, error => {
    //   loader.hide()
    //   if (error && error.body) {
    //     Vue.notify({ text: error.body, type: 'error' })
    //   }
    // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isUpdate: false,
      amount: 0,
      repetitive_count: '',
      duration: '',
      slot_time: [],
      title: '',
      operator_ad_slot_list: [],
      durationErrors: [],
      time: [],
      repetitiveCountErrors: [],
      slotTimeErrors: [],
      orgNameErrors: [],
      amountErrors: [],
    }
  },
  computed: {
    formReady () {
      return !this.durationErrors.length && !this.repetitiveCountErrors.length && !this.slotTimeErrors.length
    },
    fields () {
      if (this.operator_role) {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        },{
          name: '__slot:marker',
          width: '30px',
          dataClass: 'text-center',
        },
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'slot_time',
          title: 'Slot Time',
        },
        {
          name: 'duration',
          title: 'Duration',
        },
        {
          name: 'repetitive_count',
          title: 'Repetitive Count',
        }]
      } else {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%'
        },{
          name: '__slot:marker',
          width: '30px',
          dataClass: 'text-center',
        },
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'slot_time',
          title: 'Slot Time',
        },
        {
          name: 'duration',
          title: 'Duration',
        },
        {
          name: 'repetitive_count',
          title: 'Repetitive Count',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
      }
    },
    filteredData () {
      return search(this.term, this.operator_ad_slot_list)
    },
  },
  methods: {
    getOperatorAdvertisementSlotList () {
      this.$http.get(config.menu.host + '/advertisement_slot/operator_advertisement').then(response => {
        let index = 0
        this.operator_ad_slot_list = response.body.map(function (item) {
            item.id = index++
            item.sno = index
            item.is_existing = true
            item.checkbox_value = false
            return item
        })
        this.operator_ad_slot_list = (response.body.length > 0) ? response.body : []
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    updateOperatorAdSlot () {
      var payload = {
        id: this.id,
        org_id: this.org_id,
        org_name: this.org_name,
        slot_time: this.slot_time,
        duration: Number(this.duration),
        amount: 0,
        repetitive_count: Number(this.repetitive_count),
        ad_slot_id: this.ad_slot_id,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/advertisement_slot/operator_advertisement/' + this.id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    edit (row) {
      this.title = 'Update Operator Advertisement Slot'
      this.slot_time = row.slot_time
      this.id = row.id
      this.org_name = row.org_name
      this.org_id = row.org_id
      this.ad_slot_id = row.ad_slot_id
      this.duration = row.duration
      this.amount 	= 0
      this.repetitive_count = row.repetitive_count
      this.isshowgrid = false
      this.isshowForm = true
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getOperatorAdvertisementSlotList()
    },
    search: debounce(function (term) {
    	this.term = term
    }, 400),
  },
}
</script>
